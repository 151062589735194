import React, { useState } from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import {LoadingScreen} from "../../../components/LoadingScreen";
import ContentOtd from "./ContentOtd";


const MonitoreoOTD = () => {
	
	//Variables check loading y error
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);

	return (
		<SiteWrapper>
				<div className="container otdLive">
					<ContentOtd
						data={data}
					/>
				  </div>
			{loading ? (
				<LoadingScreen
					loading={loading}
				/>
			):null}
			
		</SiteWrapper>
	);
};

export default MonitoreoOTD;
